/* eslint-disable no-param-reassign */
import axios from 'axios';
import { toast } from 'react-toastify';
import { isLoggedIn, getToken } from './keyclockService';

// const _axios = axios.create();

// const configure = () => {
//   _axios.interceptors.request.use(config => {
//     if (UserService.isLoggedIn()) {
//       const cb = () => {
//         config.headers.Authorization = `Bearer ${UserService.getToken()}`;
//         return Promise.resolve(config);
//       };
//       return UserService.updateToken(cb);
//     }
//   });
// };

// 创建axios请求实例
const request = axios.create({
  // baseURL: window.CTX, // 设置跨域代理接口统一的前置地址
  timeout: 500000,
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }
});

// 添加请求拦截器
request.interceptors.request.use(
  config => {
    if (
      config.url &&
      typeof config.url === 'string' &&
      config.url?.indexOf('/account/sessions') === -1
    ) {
      config.headers.clientId = 'WEBAPP';
      config.headers.clientSecret = 'AKIAQQYMVOW5Y2250011GG';
    }
    if (isLoggedIn(window.KEYCLOAKINSTANCE)) {
      const newConfig = config;
      newConfig.headers.Authorization = `Bearer ${getToken(
        window.KEYCLOAKINSTANCE
      )}`;
      return Promise.resolve(newConfig);
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 添加响应拦截器
request.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    const status = error.response && error.response.status;
    if (
      error.code === 'ECONNABORTED' &&
      error.message.indexOf('timeout') !== -1
    ) {
      toast.error('Get data timeout');
    } else if (status >= 500) {
      toast.error('Please try again later for busy services');
    } else if (status >= 400) {
      if (error.response?.data?.error || error.response?.data?.message) {
        toast.error(
          error.response?.data?.error || error.response?.data?.message
        );
      } else {
        toast.error('The accessed content does not exist');
      }
    }
    if (error.toString() === 'Error: Network Error') {
      toast.error('Get data timeout');
    }
    return Promise.reject(error);
  }
);

const get = (url, config) => {
  return request.get(url, config);
};

const post = (url, data, config) => {
  return request.post(url, data, config);
};
const httpDelete = (url, config) => {
  return request.delete(url, config);
};
const put = (url, data, config) => {
  return request.put(url, data, config);
};
// const postform = (url, data) => {
//   return request({
//     method: 'post',
//     url,
//     data,
//     transformRequest: [
//       () => {
//         let ret = '';
//         for (const it in data) {
//           ret += `${encodeURIComponent(it)}=${encodeURIComponent(data[it])}&`;
//         }
//         ret = ret.substring(0, ret.lastIndexOf('&'));
//         return ret;
//       }
//     ],
//     headers: {
//       'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
//     }
//   });
// };
const postFormData = (url, data) => {
  return request({
    method: 'post',
    url,
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  // .catch((e) => {
  // 	console.error(e);
  // });
};

export { get, post, postFormData, httpDelete, put };
