import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useNavigate } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { Col, Row } from 'react-bootstrap';
import styles from './styles.module.css';
import './style.css';
import logo from '../../../assets/logos/logo.svg';
import { navBarRoute } from '../../../helpers/landingContent';
import {
  doLogout,
  getUsername,
  goAccountConsole,
  goAdminConsole,
  isAdmin,
  hasTemplateRight,
  isNusUser,
  getAccountUrl
} from '../../../services/keyclockService';

const TopBar = () => {
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();
  const [activeKey, setActiveKey] = useState('');
  const handleOnSelect = (eventKey, event) => {
    if (eventKey.startsWith('/')) {
      event.preventDefault();
      navigate(`${eventKey}`);
      return;
    }
    const { pathname } = window.location;
    if (pathname !== '/') {
      navigate(`/${eventKey}`);
      return;
    }
    event.preventDefault();
    setTimeout(() => {
      document.querySelector(eventKey)?.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }, 100);
  };

  const handleLogin = () => {
    if (keycloak.authenticated) {
      navigate('/dashboard');
    } else {
      keycloak.login();
    }
  };

  useEffect(() => {
    if (keycloak.authenticated) {
      navigate('/dashboard');
    }
  }, [keycloak.authenticated]);
  useEffect(() => {
    const { hash, pathname } = window.location;
    if (hash) {
      setActiveKey(hash);
    }
    if (pathname !== '/') {
      setActiveKey(pathname);
    }
  }, [window.location.href]);
  return (
    <div className={`${styles.topbar}`}>
      <div className="wrap">
        <Row>
          <Col>
            {keycloak.authenticated &&
            window.location.pathname !== '/' &&
            window.location.pathname.indexOf('/#') === -1 ? (
              <Navbar
                sticky="top"
                bg="white"
                expand="md"
                collapseOnSelect
                className="pt-5"
              >
                <Container fluid className="px-0">
                  <Navbar.Brand
                    href="/"
                    className="text-primary fw-bolder fs-2 py-1"
                  >
                    <img
                      src={logo}
                      className={`${styles.logo} d-inline-block align-top img-fluid`}
                      alt="TezSign logo"
                    />
                  </Navbar.Brand>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Offcanvas
                    id="basic-navbar-nav"
                    aria-labelledby="basiclabel-navbar-nav"
                    placement="top"
                    className={styles.offcanvas}
                  >
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title id="basiclabel-navbar-nav" />
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      <Nav
                        className={`justify-content-end px-0 flex-grow-1 ${styles.navbar} align-items-center`}
                        onSelect={handleOnSelect}
                        activeKey={activeKey}
                      >
                        <Nav.Link
                          key="dashboard"
                          href="/dashboard"
                          className="px-0 bodybig mx-4"
                        >
                          Dashboard
                        </Nav.Link>
                      </Nav>
                    </Offcanvas.Body>
                  </Navbar.Offcanvas>
                  <div>
                    <NavDropdown
                      title={getUsername(keycloak)?.slice(0, 1).toUpperCase()}
                      bsPrefix={styles.userinfo_btn}
                      id="user-info"
                      className="bodybig ms-4 text-center d-inline-block align-middle"
                    >
                      <div className={styles.dropdown_area}>
                        <Button
                          bsPrefix="btn_default_sm mb-4"
                          className={`${styles.spec_btn} bodybold ellipsis`}
                        >
                          Hi {getUsername(keycloak)}
                        </Button>
                        <NavDropdown.Item
                          className="body mb-2"
                          onClick={() => {
                            return goAccountConsole(keycloak);
                          }}
                        >
                          <i className="drop_icon_profile me-4 d-inline-block" />
                          My Profile
                        </NavDropdown.Item>

                        {/* <NavDropdown.Item
                          className="body mb-1"
                          onClick={() => navigate('/settings')}
                        >
                          <i className="drop_icon_set me-4 d-inline-block" />
                          Settings
                        </NavDropdown.Item> */}
                        <NavDropdown.Item
                          className="body mb-0"
                          onClick={() => {
                            doLogout(keycloak);
                          }}
                        >
                          <i className="drop_icon_logout me-4 d-inline-block" />
                          Log Out
                        </NavDropdown.Item>
                      </div>
                    </NavDropdown>
                    {/* {isNusUser(keycloak) && (
                      <div
                        className={`ms-4 ${styles.nus_logo} d-inline-block align-middle`}
                      />
                    )} */}
                  </div>
                </Container>
              </Navbar>
            ) : (
              <Navbar
                sticky="top"
                bg="white"
                expand="xl"
                collapseOnSelect
                className="pt-5"
              >
                <Container fluid className="px-0">
                  <Navbar.Brand
                    href="/"
                    className="text-primary fw-bolder fs-2 py-1"
                  >
                    <img
                      src={logo}
                      className={`${styles.logo} d-inline-block align-top img-fluid`}
                      alt="TezSign logo"
                    />
                  </Navbar.Brand>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Offcanvas
                    id="basic-navbar-nav"
                    aria-labelledby="basiclabel-navbar-nav"
                    placement="top"
                    className={styles.offcanvas}
                  >
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title id="basiclabel-navbar-nav" />
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      <Nav
                        className={`justify-content-end px-0 flex-grow-1 ${styles.navbar} align-items-center`}
                        onSelect={handleOnSelect}
                        activeKey={activeKey}
                      >
                        {/* {navBarRoute.map((item, index) => {
                          return (
                            <Nav.Link
                              key={index}
                              href={item.path}
                              className="px-0 bodybig mx-4"
                              style={
                                index === navBarRoute.length - 1
                                  ? { borderBottom: 'none' }
                                  : null
                              }
                            >
                              {item.name}
                            </Nav.Link>
                          );
                        })} */}
                        {
                          <Button
                            // href="/login"
                            onClick={handleLogin}
                            className={`${styles.mobile_btn} mx-4`}
                            bsPrefix="btn_outline_md"
                          >
                            Log In
                          </Button>
                        }
                      </Nav>
                    </Offcanvas.Body>
                  </Navbar.Offcanvas>
                </Container>
              </Navbar>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default TopBar;
