import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.css';

const SidebarNav = ({ handleFilterByNav, state, menus }) => {
  return (
    <div className={`${styles.tableNavigationContainer}`}>
      {menus.map(({ Icon, title, id }, index) => (
        <React.Fragment key={title}>
          <div
            aria-hidden
            className={
              state === id ? `${styles.current} default-color` : 'default-color'
            }
            onClick={() => {
              handleFilterByNav(id);
            }}
            style={{ cursor: 'pointer' }}
          >
            <div key={title} className={styles.tab}>
              {/* <i className={styles[Icon]} /> */}
              <span className="bodybold">{title}</span>
            </div>
          </div>
          {index !== menus.length - 1 && <div className={styles.divider} />}
        </React.Fragment>
      ))}
    </div>
  );
};
SidebarNav.propTypes = {
  handleFilterByNav: PropTypes.func.isRequired,
  state: PropTypes.number.isRequired,
  menus: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
export default SidebarNav;
